import moment from "moment";
import moment_tz from "moment-timezone";

export interface ITimeZone {
  label: string;
  value: any;
}

export const TimeUtil = {
  getListofTimeZones(): ITimeZone[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const timeZoneList: ITimeZone[] = Intl.supportedValuesOf("timeZone").reduce(
      (filtered, name) => {
        let timezoneStr = Intl.DateTimeFormat("default", {
          timeZoneName: "short",
          timeZone: name,
        })
          .formatToParts()
          .find((i) => i.type === "timeZoneName")?.value;
        timezoneStr = timezoneStr?.replace("GMT", "UTC");
        if (timezoneStr?.includes("UTC"))
          filtered.push({ label: `${name} (${timezoneStr})`, value: name });
        return filtered;
      },
      []
    );
    return timeZoneList;
  },

  convertUTCToTimeZoneValues(utcTimeStamp: string, timeZone: string): any {
    const dateTz = new Date(parseInt(utcTimeStamp)).toLocaleString("en-US", {
      timeZone: timeZone,
    });
    return moment(dateTz).valueOf();
  },

  getUTCTimeStampForSelectedTimezone(
    timestamp: string,
    selectedTimezone: string
  ): any {
    if (timestamp === null || timestamp === "") {
      return "";
    }
    const browserTimeZone = moment_tz();
    const browserUTCOffset = browserTimeZone.utcOffset(); //gets the utc offset for user's browser timezone
    browserTimeZone.tz(selectedTimezone); //convert it to selected timezone
    const selectedTzUTCOffset = browserTimeZone.utcOffset(); //gets the utc offset for user selected timezone
    const diffinMilliSecs =
      (browserUTCOffset - selectedTzUTCOffset) * 60 * 1000;
    timestamp += diffinMilliSecs;
    return timestamp;
  },
  // returns offseted UTC timestamp value considering only browser timezone.
  // For user defined timeZones consider other functions.
  getUTCOffsetTimeStamp(): number {
    const currentDate = new Date();
    const offset = currentDate.getTimezoneOffset() * 60 * 1000; // Offset in milliseconds
    const currentTimestamp = Date.now(); // Current timestamp in milliseconds
    const utcOffsetTimeStamp = currentTimestamp + offset;
    return utcOffsetTimeStamp;
  },
  /**
   * get formatted date
   * @returns string
   */
  getFormattedDate(
    timeStamp: string,
    dateformat: any,
    timeZone: string,
    hasTimeZone: boolean
  ): string {
    if (hasTimeZone) {
      const dateTz = new Date(parseInt(timeStamp)).toLocaleString("en-US", {
        timeZone: timeZone,
      });
      const zone = moment_tz.tz(timeZone).format("Z").slice(0, -3);
      const formattedTimeZone =
        zone.indexOf("0") !== -1 || zone.indexOf("1") !== -1
          ? "UTC" + zone.replace("+00", "")
          : zone;
      return moment(dateTz).format(dateformat) + " " + `(${formattedTimeZone})`;
    }
    return (
      moment(parseInt(timeStamp)).utc().format(dateformat) +
      " " +
      `(${timeZone})`
    );
  },

  /**
   * get formatted date range
   * @returns string
   */
  getFormattedDateRange(
    timeStamp: string,
    dateformat: any,
    timeZone: string,
    hasTimeZone: boolean
  ): string {
    const tmpArr = timeStamp.split(",");
    if (hasTimeZone) {
      //Convert UTC timestamp to user selected timezone
      const timeStampStart = new Date(parseInt(tmpArr[0])).toLocaleString(
        "en-US",
        { timeZone: timeZone }
      );
      const timeStampEnd = new Date(parseInt(tmpArr[1])).toLocaleString(
        "en-US",
        { timeZone: timeZone }
      );
      tmpArr[0] = moment(timeStampStart).format(dateformat);
      tmpArr[1] = moment(timeStampEnd).format(dateformat);
      const zone = moment_tz.tz(timeZone).format("Z").slice(0, -3);
      timeZone =
        zone.indexOf("0") !== -1 || zone.indexOf("1") !== -1
          ? "UTC" + zone.replace("+00", "")
          : zone;
    } else {
      tmpArr[0] = moment(parseInt(tmpArr[0])).utc().format(dateformat);
      tmpArr[1] = moment(parseInt(tmpArr[1])).utc().format(dateformat);
    }

    const returnStr =
      "<i style='display: inline-block;width: 1rem;'></i>" +
      tmpArr[0] +
      "<br/>~ " +
      tmpArr[1];
    return returnStr + " " + `(${timeZone})`;
  },
};
